<template>
  <div>
    <div class="dispatchingWorkers">
      <van-nav-bar left-text="集体派工"
                   left-arrow
                   @click-left="onClickLeft"
                   :fixed="true">
        <template #right> </template>
      </van-nav-bar>
      <div class="fixedH"
           style="height: 1.22667rem"></div>
      <div class="content">
        <div class="contentTop">
          <div class="contentTopHeader">
            <img src="@/assets/img/jibenxinxitubiao.png"
                 alt="" />
            <span>基本信息</span>
          </div>
          <div class="contentTopMain">
            <div class="contentTopMainInfo">
              <div class="contentTopMainInfoIcon">
                <img src="@/assets/img/paichanriqi.png"
                     alt="" />
              </div>
              <div class="contentTopMainInfoWord">单号</div>
              <div class="contentTopMainInfoInput"
                   style="font-size:12px">{{ listno }}</div>
            </div>
            <div class="contentTopMainInfo">
              <div class="contentTopMainInfoIcon">
                <img src="@/assets/img/paichanriqi.png"
                     alt="" />
              </div>
              <div class="contentTopMainInfoWord">排产日期</div>
              <div class="contentTopMainInfoInput">{{ yymmdd }}</div>
            </div>
            <div class="contentTopMainInfo">
              <div class="contentTopMainInfoIcon">
                <img src="@/assets/img/chanxian.png"
                     alt="" />
              </div>
              <div class="contentTopMainInfoWord">产线</div>
              <div class="contentTopMainInfoInput">{{ linename }}</div>
            </div>
            <div class="contentTopMainInfo">
              <div class="contentTopMainInfoIcon">
                <img src="@/assets/img/gongjian.png"
                     alt="" />
              </div>
              <div class="contentTopMainInfoWord">工件</div>
              <div class="contentTopMainInfoInput">{{ partname }}</div>
            </div>
            <div class="contentTopMainInfo">
              <div class="contentTopMainInfoIcon">
                <img src="@/assets/img/shuliang.png"
                     alt="" />
              </div>
              <div class="contentTopMainInfoWord">数量</div>
              <div class="contentTopMainInfoInput">{{ plannum }}</div>
            </div>
          </div>
        </div>
        <div class="contentMain"
             v-if="baseInfoData && dataData && dataData.length > 0">
          <div class="contentMainHeader">
            <div class="contentMainHeaderLeft">
              <img src="@/assets/img/wqdqwdww.png"
                   alt="" />
              <span>派工明细</span>
            </div>
            <div class="contentMainHeaderRight"
                 v-if="readyDispatchingWorkers == 2">
              <van-icon name="replay"
                        color="#0782f5"
                        size="18" />
              <span class="quanxuan"
                    style="margin-left: 5px;font-size:16px"
                    @click="retrieve">重新获取</span>
            </div>
            <div class="contentMainHeaderRight"
                 v-if="readyDispatchingWorkers == 2">
              <img src="@/assets/img/xiezhurenyuantianjia.png"
                   alt="" />
              <span class="quanxuan"
                    style="margin-left: 5px;font-size:16px"
                    @click="addNew">新增</span>
            </div>
          </div>
          <div class="contentMainTable"
               style="padding-top: 15px; padding-bottom: 15px">
            <div style="width: 100%; overflow: auto">
              <table style="border-collapse: collapse; width: 100%">
                <tr style="background: #e4e9f3; height: 40px">
                  <th style="border-right: 1px solid #ccc"></th>
                  <th style="border-right: 1px solid #ccc">工号</th>
                  <th style="border-right: 1px solid #ccc">
                    <div style="width:100px">姓名</div>
                  </th>
                  <th style="border-right: 1px solid #ccc">
                    <div style="width:100px">岗位</div>
                  </th>
                  <th style="border-right: 1px solid #ccc">
                    <div style="width:80px">状态</div>
                  </th>
                  <th>操作</th>
                </tr>
                <tr v-for="(value, key) in dataData"
                    :key="key">
                  <td style="border-right: 1px solid #ccc; font-size: 15px">
                    <span style="
                      font-size: 15px;
                      display: block;
                      width: 20px;
                      text-align: center;
                    ">{{ key + 1 }}</span>
                  </td>
                  <td style="border-right: 1px solid #ccc">
                    <!-- <span style="font-size: 15px">{{ value.empcode }}</span> -->
                    <div class="h5Select"
                         @click="selectEmp(value, key)">
                      <input v-model="value.empcode"
                             class="h5SelectInput"
                             readonly
                             type="text" />
                      <van-icon class="h5SelectIcon"
                                name="arrow-down" />
                    </div>
                  </td>
                  <td style="border-right: 1px solid #ccc;text-align: center;">
                    <div style="font-size: 15px">{{ value.empname }}</div>
                  </td>
                  <td style="border-right: 1px solid #ccc;text-align: center;">
                    <div style="font-size: 15px">{{ value.jobname }}</div>
                  </td>
                  <td style="border-right: 1px solid #ccc;text-align: center;">
                    <div class="h5Select"
                         @click="selectStatus(value, key)">
                      <input v-model="value.status"
                             class="h5SelectInput"
                             readonly
                             type="text" />
                      <van-icon class="h5SelectIcon"
                                name="arrow-down" />
                    </div>
                  </td>
                  <td>
                    <div style="
                      width: 50px;
                      text-align: center;
                      font-size: 15px;
                      color: blue;
                      text-decoration: underline;
                    "
                         @click="hfwjehed(value, key)">
                      删除
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>

    </div>
    <!-- :job="true" -->
    <PopTree ref="popTreeNode"
             :whichTree="'employee'"
             :multiseltree="false"
             @treeSelectValEvent="treeSelectValEvent">
    </PopTree>

    <PopTree ref="popTreeNode2"
             :whichTree="'employee'"
             :multiseltree="false"
             @treeSelectValEvent="treeSelectValEvent2">
    </PopTree>
    <!-- 新增派工弹窗 -->
    <van-dialog v-model="showAddPgDia"
                class="add_new_paigong"
                show-cancel-button
                :confirm-button-text="$t('module.confirm')"
                confirm-button-color='#0782F5'
                :beforeClose="addNewPg">
      <template #title>
        <div class="title">新增派工</div>
      </template>
      <div class="content">
        <van-form label-align="right">
          <van-field readonly
                     label="工号"
                     v-model="addForm.empcode"
                     placeholder="选择员工"
                     right-icon="arrow-down"
                     @click="selectFuZeRen" />
          <van-field readonly
                     label="姓名"
                     placeholder="姓名"
                     v-model="addForm.empname" />
          <van-field readonly
                     label="工位"
                     v-model="addForm.jobName"
                     placeholder="选择工位"
                     right-icon="arrow-down"
                     @click="showGjPicker = true" />
        </van-form>
      </div>
    </van-dialog>
    <!-- 选择工位弹窗 -->
    <van-popup v-model="showGjPicker"
               round
               position="bottom">
      <van-picker show-toolbar
                  :columns="gjcolumns"
                  value-key="jobname"
                  :confirm-button-text="$t('module.confirm')"
                  :cancel-button-text="$t('module.cancel')"
                  @cancel="showGjPicker = false"
                  @confirm="onConfirmGj" />
    </van-popup>

    <!-- 选择状态弹窗 -->
    <van-popup v-model="showStaPicker"
               round
               position="bottom">
      <van-picker show-toolbar
                  :columns="perstatus"
                  :confirm-button-text="$t('module.confirm')"
                  :cancel-button-text="$t('module.cancel')"
                  @cancel="showStaPicker = false"
                  @confirm="onConfirmSta" />
    </van-popup>
  </div>
</template>
<script>
import { parseTime } from "@/utils";
import {
  getGroupPgInfo,
  addGroupPg,
  deleteGroupPg,
  savePgDetailEmpInfo,
  getJobList,
  reGetPgDetail
} from "@api/paigong.js";
import { GetFilterDropDownList } from '@api/wx.js'
import PopTree from "@components/PopTree/index.vue";
import { Toast, Dialog } from "vant";
export default {
  name: "dispatchingWorkers",
  components: {
    PopTree,
  },
  data () {
    const userInfo = localStorage.userInfo
      ? JSON.parse(localStorage.userInfo)
      : {};
    return {
      userInfo,
      autoid: this.$route.query.autoid || null,
      bd: this.$route.query.bd || "",
      ed: this.$route.query.ed || "",
      yymmdd: this.$route.query.yymmdd || "",
      linename: this.$route.query.linename || "",
      machinename: this.$route.query.machinename || "",
      partname: this.$route.query.partname || "",
      plannum: this.$route.query.plannum || null,
      listno: this.$route.query.listno || "",
      baseInfoData: null,
      dataData: null,
      showJiTaiPicker: false,
      showJiTaiSelectIndex: 0,
      bsMachineList: [],
      machineList: [],
      showGongXuPicker: false,
      showGongXuIndex: 0,
      bsProcessList: [],
      processList: [],
      fuZeRenIndex: 0,
      selectXzryObj: null,
      xieZryData: [],
      successpath: require("@/assets/img/chenggong.png"),
      errorpath: require("@/assets/img/job_booking10.png"),
      cfPgdJt: "",
      cfPgdJtId: null,
      cfPgdSl: "",
      showJiTaiSelectType: 1,
      daiBanRy: "", // 负责人
      daiBanRyId: null,
      daiBanRyCode: null,
      cfXzRdata: [],
      readyDispatchingWorkers: 2,
      showAddPgDia: false,
      addForm: {
        empid: 0,
        empcode: '',
        empname: '',
        jobid: 0,
        jobName: ''
      },
      gjcolumns: [],
      bfgjcolumns: [],
      showGjPicker: false,
      showStaPicker: false,
      hasChangeStaRow: {},
      perstatus: []
    };
  },
  created () {
    this.readyDispatchingWorkers = sessionStorage.getItem("readyDispatchingWorkers");
    this.getData();
    this.getSelJobEvent()
  },
  methods: {
    // 选择状态
    selectStatus (value, key) {
      this.showStaPicker = true
      this.hasChangeStaRow = value
      this.perstatus = []
      GetFilterDropDownList({
        moduleno: 810,
        username: this.userInfo.username,
        listsql: '@集体派工人员状态'
      }).then(res => {
        if (res.data.length > 0)
        {
          for (let i = 0; i < res.data.length; i++)
          {
            this.perstatus.push(res.data[i].dicvalue)
          }
        }
      })
    },
    // 选择状态回调
    onConfirmSta (val) {
      this.showStaPicker = false
      savePgDetailEmpInfo({
        autoid: this.hasChangeStaRow.autoid,
        empid: this.hasChangeStaRow.empid,
        username: this.userInfo.username,
        status: val
      }).then(res => {
        if (res.data[0].info)
        {
          Toast({
            message: res.data[0].info,
            icon: this.errorpath,
          });
        } else
        {
          Toast({
            message: "修改成功",
            icon: this.successpath,
          });
          this.getData();
        }
      })
    },
    // 查询工位数据
    getSelJobEvent () {
      getJobList({
        username: this.userInfo.username,
        pgid: this.$route.query.autoid
      }).then(res => {
        this.gjcolumns = res.data;
      })
    },
    // 选择工位回调
    onConfirmGj (value) {
      this.addForm.jobName = value.jobname
      this.addForm.jobid = value.jobid
      this.showGjPicker = false
    },
    // 重新获取按钮
    retrieve () {
      Dialog.confirm({
        message: '确认后将把派工明细变成最近一次派工数据，是否继续操作？',
      }).then(() => {
        // on confirm
        reGetPgDetail({
          autoid: this.autoid,
          username: this.userInfo.username
        }).then(res => {
          if (res.data[0].info)
          {
            Toast({
              message: res.data[0].info,
              icon: this.errorpath,
            });
          } else
          {
            Toast({
              message: "获取成功",
              icon: this.successpath,
            });
            this.getData();
          }
        })
      }).catch(() => {
        // on cancel
      });
    },
    // 新增按钮
    addNew () {
      this.showAddPgDia = true
      this.addForm = {
        empid: 0,
        empcode: '',
        empname: '',
        jobid: 0,
        jobName: ''
      }
    },
    // 新增派工弹窗回调
    addNewPg (action, done) {
      if (action == 'confirm')
      {
        if (!this.addForm.empcode)
        {
          Toast("请选择员工！");
          done(false);
          return false
        }
        if (!this.addForm.jobName)
        {
          Toast("请选择工位！");
          done(false);
          return false
        }
        addGroupPg({
          pg_autoid: this.autoid,
          username: this.userInfo.username,
          empid: this.addForm.empid,
          jobid: this.addForm.jobid
        }).then((res) => {
          Toast({
            message: "新增成功",
            icon: this.successpath,
          });
          this.getData();
          done();
        });
      } else
      {
        done();
      }
    },
    // 修改派工人员回调
    treeSelectValEvent2 (data) {
      savePgDetailEmpInfo({
        autoid: this.dataData[this.fuZeRenIndex].autoid,
        empid: data.empid,
        username: this.userInfo.username,
        status: this.dataData[this.fuZeRenIndex].status
      }).then(res => {
        Toast({
          message: "修改成功",
          icon: this.successpath,
        });
        this.getData();
      })
    },
    // 选择工号
    selectEmp (value, key) {
      this.fuZeRenIndex = key;
      this.$refs.popTreeNode2.module = "813";
      this.$refs.popTreeNode2.treename = "pgemptree";
      this.$refs.popTreeNode2.editstate = 2;
      this.$refs.popTreeNode2.fieldname = this.$route.query.autoid
      this.$refs.popTreeNode2.showEmpTreePop("选择派工人员");
    },
    // 新增选人回调
    treeSelectValEvent (data) {
      this.addForm.empid = data.empid
      this.addForm.empname = data.empname
      this.addForm.empcode = data.empcode
    },
    getData () {
      getGroupPgInfo({
        autoid: this.$route.query.autoid,
        yymmdd: this.$route.query.yymmdd,
        username: this.userInfo.username,
      }).then((res) => {
        if (res.data)
        {
          res.data.forEach((item) => {
            item.checked = false;
          });
          this.dataData = res.data;
        }
        if (res.baseinfo)
        {
          this.baseInfoData = res.data;
        }
      });
    },

    // 选择负责人
    selectFuZeRen (value, key) {
      this.fuZeRenIndex = key;
      this.$refs.popTreeNode.module = "813";
      this.$refs.popTreeNode.treename = "glsemptree";
      this.$refs.popTreeNode.editstate = 2;
      this.$refs.popTreeNode.fieldname = this.$route.query.autoid
      this.$refs.popTreeNode.showEmpTreePop("选择员工");
    },

    onClickLeft () {
      this.$router.push({
        path: "/dispatchingWorkers",
        query: {
          bd: this.$route.query.bd || "",
          ed: this.$route.query.ed || ""
        }
      })
    },
    // 协助人员
    hfwjehed (value, key) {
      if (this.readyDispatchingWorkers == 1)
      {
        return;
      }
      deleteGroupPg({
        autoid: value.autoid,
        username: this.userInfo.username,
      }).then((res) => {
        Toast({
          message: "删除成功",
          icon: this.successpath,
        });
        this.getData();
      });
    },
  },
};
</script>
<style lang="less" scoped>
.add_new_paigong {
  /deep/.van-dialog__header {
    padding: 20px 0;
    background-image: url("~@/assets/img/job_booking09.png");
    background-repeat: no-repeat;
    background-size: 100%;
    color: #08306b;

    .title {
      font-size: 40px;
      font-weight: bold;
      margin-bottom: 10px;
    }

    .desc {
      font-size: 28px;
    }
  }
}

.dispatchingWorkers {
  height: 100vh;

  background-image: linear-gradient(to bottom, #bcdfff, #eaedef);
  overflow: hidden;
  background-color: #eaedef;
  background-repeat: no-repeat;
  background-size: 100% 300px;
  background-position-y: 1.22667rem;
  overflow: auto;

  .van-nav-bar {
    background: #bcdfff;

    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #333;
        font-size: 0.5rem;
      }

      .van-nav-bar__text {
        color: #333;
        font-size: 36px;
        font-weight: bold;
      }
    }

    .showPopup {
      color: #333;

      span {
        color: #999;
        font-size: 24px;
        margin-left: 20px;

        .svg-icon {
          margin: 0;
        }
      }

      .svg-icon {
        // margin: 0 5px;
      }
    }
  }

  .content {
    padding-bottom: 50px;

    .contentTop {
      width: 100%;
      margin: 28px 0;
      padding: 0 26px;

      .contentTopHeader {
        display: flex;
        align-items: center;
        width: 100%;
        background: linear-gradient(90deg, #007aec 0%, #00b4ff 100%);
        padding: 20px 30px;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;

        img {
          width: 40px;
          height: 40px;
          display: block;
        }

        span {
          font-size: 32px;
          font-weight: bold;
          margin-left: 10px;
          color: #fff;
        }
      }

      .contentTopMain {
        width: 100%;
        background: #fff;
        padding: 10px 30px;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
      }

      .contentTopMainInfo {
        display: flex;
        height: 36px;
        align-items: center;
        margin-top: 0.25rem;
        margin-bottom: 0.25rem;

        .contentTopMainInfoIcon {
          width: 30px;
          height: 30px;

          img {
            width: 30px;
            height: 30px;
            display: block;
          }
        }

        .contentTopMainInfoWord {
          font-size: 30px;
          line-height: 1;
          color: #8d8d8d;
          margin-left: 16px;
          width: 2.5rem;
        }

        .contentTopMainInfoInput {
          font-size: 30px;
          font-weight: bold;
          color: #000;
          line-height: 1;
          margin-left: 16px;
        }
      }
    }

    .contentMain {
      width: 100%;
      padding: 0 26px;

      .contentMainHeaderLeft {
        display: flex;
        align-items: center;
        flex: 4;

        img {
          width: 40px;
          height: 40px;
          display: block;
        }

        span {
          font-size: 32px;
          font-weight: bold;
          margin-left: 10px;
          color: #000;
        }
      }

      .contentMainHeader {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 20px 30px;
        border-radius: 20px 20px 0 0;
        background: #ffffff;
      }
    }

    .contentMainHeaderRight {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex: 3;

      img {
        width: 40px;
        height: 40px;
        display: block;
      }

      span {
        font-size: 32px;
        line-height: 1;
        margin-left: 20px;
        color: #000;
      }
    }
  }
}

.h5Select {
  width: 170px;
  padding: 5px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  border-radius: 10px;
  border: 1px solid #d2d2d2;
  background: #fff;

  .h5SelectInput {
    border: 0;
    flex: 1;
    max-width: calc(100% - 48px);
    outline: 0 !important;
  }

  .h5SelectIcon {
    width: 48px;
    font-size: 32px;
  }
}

.h5Input {
  width: 180px;
  border: 1px solid #d2d2d2;
  outline: 0 !important;
  border-radius: 10px;
  padding-left: 8px;
}

.contentMainTable {
  width: 100%;
  overflow: hidden;
  padding: 0 0.34667rem;
  background: #fff;

  table {
    th {
      font-size: 26px;
      color: #000000;
    }

    td {
      padding: 15px 20px;

      input {
        font-size: 28px;
      }
    }
  }
}

table tr:nth-child(odd) {
  background-color: #f1f5fc;
}

table tr:nth-child(even) {
  background-color: #ffffff;
}

.detailFooter {
  width: 100%;
  display: flex;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 999;
  height: 100px;
  align-items: center;
  background: #fff;
  box-shadow: 4px 3px 15px 0px rgba(175, 175, 175, 0.21);
  padding: 0 0.69334rem;

  .footerImgIcon {
    width: 36px;
    height: 36px;
    display: block;
  }

  .footerWord {
    font-size: 36px;
    margin-left: 12px;
    line-height: 1;
  }

  .detailFooterDiv {
    display: flex;
    align-items: center;
  }

  .detailFooterShuXian {
    height: 36px;
    width: 1px;
    background: #c2c2c2;
    margin: 0 42px;
  }
}

.Assist_report {
  /deep/.van-dialog__header {
    padding: 20px 0;
    background-image: url("~@/assets/img/xiezhurenyuandb.png");
    background-repeat: no-repeat;
    background-size: 100%;
    color: #08306b;
    height: 2.3rem;

    .title {
      font-size: 52px;
      font-weight: bold;
      margin-bottom: 10px;
      padding: 20px 40px;
      text-align: left;
    }

    .desc {
      font-size: 28px;
    }
  }
}

.contentCell {
  width: 100%;
  padding: 0;

  .AssistCell {
    width: 100%;
    display: flex;
    margin-bottom: 40px;
    align-items: center;

    .AssistCellLeft {
      width: 2.3rem;
      line-height: 1;
      text-align: right;
      color: #7f7f7f;
    }

    .AssistCellRight {
      flex: 1;
      line-height: 1;
      padding-left: 42px;
    }
  }
}

.contentPersonnel {
  padding: 0 0.53333rem;
}

.contentPersonnelTop {
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #e5e5e5;
  padding-top: 35px;

  .contentPersonnelTopLeft {
    display: flex;
    align-items: center;

    .contentPersonnelTopLeftIcon {
      width: 34px;
      height: 35px;
    }

    .contentPersonnelTopLeftSpan {
      font-size: 32px;
      line-height: 1;
      margin-left: 10px;
    }
  }

  .contentPersonnelTopRight {
    display: flex;
    align-items: center;

    .contentPersonnelTopRightIcon {
      width: 25px;
      height: 25px;
      margin-right: 10px;
    }

    span {
      font-size: 32px;
      line-height: 1;
    }
  }
}

.contentPersonnelMain {
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
  max-height: 300px;
  overflow: auto;
  min-height: 80px;
}

.contentPersonnelMainCell {
  display: flex;
  align-items: center;

  .contentPersonnelMainIcon {
    width: 32px;
    height: 34px;
  }

  span {
    line-height: 1;
    font-size: 32px;
    margin: 0 14px;
  }
}

.SplitDispatchOrder_report {
  /deep/.van-dialog__header {
    padding: 20px 0;
    background-image: url("~@/assets/img/weffwfeffefe.png");
    background-repeat: no-repeat;
    background-size: 100%;
    color: #08306b;
    height: 2.3rem;

    .title {
      font-size: 52px;
      font-weight: bold;
      margin-bottom: 10px;
      padding: 20px 40px;
      text-align: left;
      padding-bottom: 0;
      padding-top: 10px;
    }

    .des {
      font-size: 28px;
      text-align: left;
      padding-left: 40px;
    }
  }
}

.showPopup {
  color: #333;

  span {
    color: #999;
    font-size: 24px;
    margin-left: 10px;

    .svg-icon {
      margin: 0;
    }
  }

  .svg-icon {
    // margin: 0 5px;
  }
}
</style>